import React, { useEffect, useState } from 'react';
import {
    FieldControl,
    getFormToolControlInfosByFormToolControlId,
    getFormToolControlInfoByLanguage,
    getFormToolControlOptionInfoByLanguage,
    getFormToolControlOptionsByFormToolControlId,
    getFormToolControlOptionInfosByFormToolControlId
} from "ticketino-api-client";
import Checkbox from './../FieldControls/Checkbox';
import RadioButtonList from './../FieldControls/RadioButtonList';
import Dropdown from './../FieldControls/Dropdown';
import Textbox from './../FieldControls/Textbox';
import EmailTextbox from './../FieldControls/EmailTextbox';
import Textarea from './../FieldControls/Textarea';
import DatePicker from './../FieldControls/DatePicker';
import CheckboxList from './../FieldControls/CheckboxList';
import OptionTextBox from './../Utils/OptionTextBox';

function SaveToField({ formToolControl, language, addOption, removeOption }) {

    const [formToolControlInfo, setFormToolControlInfo] = useState({});
    const [formToolControlOptions, setFormToolControlOptions] = useState([]);
    const [selectedFormToolControlOption, setSelectedFormToolControlOption] = useState({});
    //used to keep track of the ticket types added in this component
    const [optionsAdded, setOptionsAdded] = useState([]);
    const [optionText, setOptionText] = useState("");

    useEffect(() => {
        loadFormToolControl(formToolControl.id);
    }, [])

    const loadFormToolControl = async (formToolControlId) => {
        try {
            const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
            const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);

            setFormToolControlInfo(formToolControlInfo);

            const formToolControlOptions = await getFormToolControlOptionsByFormToolControlId(formToolControlId);

            let updatedFormToolControlOptions = await Promise.all(
                formToolControlOptions.map(async (formToolControlOption) => {

                    const formToolControlOptionInfos = await getFormToolControlOptionInfosByFormToolControlId(formToolControlOption.id);
                    const formToolControlOptionInfo = getFormToolControlOptionInfoByLanguage(formToolControlOptionInfos, language);

                    return { ...formToolControlOption, info: formToolControlOptionInfo }
                })
            );

            updatedFormToolControlOptions = updatedFormToolControlOptions.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

            setFormToolControlOptions(updatedFormToolControlOptions);
        }
        catch (error) {
            console.error(error);
        }
    }

    const modifySelectedOption = (formToolControlOption, value) => {

        //in case that the value for the option is predefined in the options (like a dropdown) we must take the predefined value
        //otherwise when the option is not defined (like a text input) we must take the value defined in the form
        let optionValue = formToolControlOption?.id ?? value;

        const addOptionResult = addOption({
            field: formToolControl.fieldIdToSave,
            value: optionValue,
            formToolControlId: formToolControl.id,
            //define the option text in case that it's needed
            optionText: formToolControlOption == null || formToolControlOption?.hasOptionText === true ?
                value :
                (formToolControlOption?.info?.optionText ?? "")
        });

        let options = optionsAdded.filter(o => o.field !== formToolControl?.fieldIdToSave);
        options.push(addOptionResult);

        setOptionsAdded(options);
    }

    const onChange = async (e) => {

        const value = e.value;

        if (value == null || value === "") {

            if (formToolControl.onlyAllowOneOptionSelected) {
                optionsAdded.forEach(o => {
                    removeOption(o);
                });

                setOptionsAdded([]);
            }

            return;
        }

        const formToolControlOption = formToolControlOptions.find(ftco => ftco.id === parseInt(value));
        setSelectedFormToolControlOption(formToolControlOption);

        if (selectedFormToolControlOption?.hasOptionText !== true) {
            setOptionText("");
        }

        modifySelectedOption(formToolControlOption, value);
    }

    const onOptionTextChange = async (value) => {
        setOptionText(value);
        modifySelectedOption(selectedFormToolControlOption, value);
    }

    return (
        <>
            {formToolControl.fieldControlId === FieldControl.Checkbox && <Checkbox formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.CheckboxList && <CheckboxList formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} formToolControlOptions={formToolControlOptions} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.RadioButtonList && <RadioButtonList formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} formToolControlOptions={formToolControlOptions} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.Dropdown && <Dropdown formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} formToolControlOptions={formToolControlOptions} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.Textbox && <Textbox formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.EmailTextbox && <EmailTextbox formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.Textarea && <Textarea formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.DatePicker && <DatePicker formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.DatePicker && <DatePicker formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} onChange={onChange} />}
            {selectedFormToolControlOption?.hasOptionText === true && <OptionTextBox optionText={optionText} onChange={onOptionTextChange} />}
        </>
    );
}

export default SaveToField;