import React, { useEffect, useState } from 'react';
import { DataSaveType, FieldControl, getFormToolControlInfoByLanguage, getFormToolControlInfosByFormToolControlId, getFormToolControlOptionInfoByLanguage, getFormToolControlOptionInfosByFormToolControlId } from "ticketino-api-client";

function StaticField({ formToolControl, language }) {

        const [formToolControlInfo, setFormToolControlInfo] = useState({});

        useEffect(() => {
            loadFormToolControl(formToolControl.id)
        }, [])

        const loadFormToolControl = async (formToolControlId) => {
            try {
                const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
                const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);
                setFormToolControlInfo(formToolControlInfo);
            }
            catch (error) {
                console.error(error);
            }
        }
    return (
        <div className="row mb-3 pt-4">
            <h4>{formToolControlInfo?.titleText ?? ""}</h4>
        </div>
    );
}

function CreateATicket({ formToolControl, language, index }) {

    const [formToolControlInfo, setFormToolControlInfo] = useState({});
    const [value, setValue] = useState("");

    useEffect(() => {
        loadFormToolControl(formToolControl.id);
    }, [])

    const loadFormToolControl = async (formToolControlId) => {
        try {

            const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
            const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);

            setFormToolControlInfo(formToolControlInfo);

            var formToolControlOptionInfos = await Promise.all(
                formToolControl.values.map(async (formToolControlOptionId) => {

                    const formToolControlOptionInfos = await getFormToolControlOptionInfosByFormToolControlId(formToolControlOptionId);
                    const formToolControlOptionInfo = getFormToolControlOptionInfoByLanguage(formToolControlOptionInfos, language);

                    return formToolControlOptionInfo;
                })
            );

            if (formToolControlOptionInfos.length > 0) {

                var values = formToolControlOptionInfos.map(info => info?.optionText).join();

                setValue(values);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {value !== "" && <div className={`row mb-2 ${index % 2 === 0 ? "bg-light" : ""}`}>
                <div className="col-md-4">{formToolControlInfo?.titleText ?? ""}</div>
                <div className="col-md-8 ps-4">{value}</div>
            </div>}
        </>
    );
}

function SaveToField({ formToolControl, language, index }) {

    const [formToolControlInfo, setFormToolControlInfo] = useState({});
    const [value, setValue] = useState("");

    useEffect(() => {
        loadFormToolControl(formToolControl.id);
    }, [])

    const loadFormToolControl = async (formToolControlId) => {
        try {
            const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
            const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);

            setFormToolControlInfo(formToolControlInfo);

            const fieldControlsWithInputText = [
                FieldControl.Textbox,
                FieldControl.EmailTextbox,
                FieldControl.MultilineTextbox,
                FieldControl.TelephoneNumberTextbox,
                FieldControl.TelephoneNumberTextbox
            ];

            //when it's a control that doesn't have options we can display the value directly
            if (fieldControlsWithInputText.includes(formToolControl.fieldControlId)) {
                setValue(formToolControl.values[0] ?? "");
            }
            else {
                var formToolControlOptionInfos = await Promise.all(
                    formToolControl.values.map(async (formToolControlOptionId) => {

                        const formToolControlOptionInfos = await getFormToolControlOptionInfosByFormToolControlId(formToolControlOptionId);
                        const formToolControlOptionInfo = getFormToolControlOptionInfoByLanguage(formToolControlOptionInfos, language);

                        return formToolControlOptionInfo;
                    })
                );

                if (formToolControlOptionInfos.length > 0) {

                    var values = formToolControlOptionInfos.map(info => info?.optionText).join();

                    setValue(values);
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {value !== "" && <div className={`row mb-2 ${index % 2 === 0 ? "bg-light" : ""}`}>
                <div className="col-md-4">{formToolControlInfo?.titleText ?? ""}</div>
                <div className="col-md-8 ps-4">{value}</div>
            </div >}
        </>
    );
}

function SummaryPage({ formToolControls, language }) {

    let i = 0;

    return (
        <>
            {formToolControls.filter(ftc => ftc.values.length > 0 || ftc.dataSaveTypeId === DataSaveType.StaticField).map((ftc, index) => {
                if (ftc.dataSaveTypeId === DataSaveType.StaticField) {
                    i = 0;
                    return (<StaticField formToolControl={ftc} language={language} index={i} />);
                }
                else if (ftc.dataSaveTypeId === DataSaveType.CreateATicket) {
                    ++i;
                    return (<CreateATicket formToolControl={ftc} language={language} index={i} />);
                } 
                else if (ftc.dataSaveTypeId === DataSaveType.SaveToField) {
                    ++i;
                    return (<SaveToField formToolControl={ftc} language={language} index={i} />);
                }
                else {
                    return (<div></div>);
                }
            })}
        </>
    );
}

export default SummaryPage;