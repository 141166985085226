import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPdfTicketsOfOrder, getPkPassOfTicket, getReceiptOfOrder } from "ticketino-api-client";

function ConfirmationPage({ order, organizerId }) {

    const { t } = useTranslation();

    const downloadReceiptOfOrder = async () => {
        await getReceiptOfOrder(order.id, organizerId);
    };

    const downloadGetPdfTicketsOfOrder = async () => {
        await getPdfTicketsOfOrder(order.id, organizerId);
    };

    const downloadPkPassOfTicket = async (ticketId) => {
        await getPkPassOfTicket(ticketId);
    };

    return (
        <>
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    {t("You have received a confirmation by e-mail")}
                </div>
            </div>
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    <h4>{t("Receipt")}</h4>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-8">
                    <button type="button" className="btn btn-primary" onClick={downloadReceiptOfOrder}>{t("Download Receipt")}</button>
                </div>
            </div>
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    <h4>{t("Tickets")}</h4>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-8">
                    <button type="button" className="btn btn-primary" onClick={downloadGetPdfTicketsOfOrder}>{t("Download Tickets")}</button>
                </div>
            </div>

            {order.tickets.map((ticket, index) => 
                <div className="row mb-2">
                    <div className="col-md-8">
                        <button type="button" className="btn btn-primary" onClick={() => { downloadPkPassOfTicket(ticket.id) }}>{t("Download Passbook Ticket")} ({index + 1} / {order.tickets.length})</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ConfirmationPage;