import React from 'react';

function Checkbox({ formToolControl, formToolControlInfo, onChange }) {

    const onChangeCheckbox = async (e) => {
        
        //when the checkbox is checked we must pass the value in the field description
        //otherwise an empty value to clean the selected option
        const value = e.target.checked ? formToolControlInfo.fieldDescription : '';

        onChange({ value: value });
    }

    return (
        <div className="form-group">
            {formToolControlInfo?.titleText && <p className="form-label">{formToolControlInfo.titleText}</p>}
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id={formToolControl.id} checked={formToolControl.value} onChange={onChangeCheckbox} required={formToolControl.isMandatory} />
                <label className="form-check-label" htmlFor={formToolControl.id}>{formToolControlInfo.fieldDescription}</label>
            </div>
        </div>
    );
}

export default Checkbox;