import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "Next": "Next",
            "Submit": "Submit",
            "Submit Promotion": "Submit",
            "Go Back": "Go back",
            "Select an option": "Please select an option",
            "Promotion Code": "Promotion Code",
            "Delivery and Payment Fee": "Delivery and Payment Fee",
            "You have received a confirmation by e-mail": "You have received a confirmation by e-mail",
            "Receipt": "Receipt",
            "Download Receipt": "Download Receipt",
            "Tickets": "Tickets",
            "Download Tickets": "Download Tickets",
            "Download Passbook Ticket": "Download Passbook Ticket",
            "Delivery Method": "Delivery Method",
            "Payment Method": "Payment Method",
            "Order Total Price": "Order Total Price"
        }
    },
    fr: {
        translation: {
            "Next": "Next",
            "Submit": "Submit",
            "Submit Promotion": "Submit",
            "Go Back": "Go back",
            "Select an option": "Veuillez sélectionner une option",
            "Promotion Code": "Promotion Code",
            "Delivery and Payment Fee": "Delivery and Payment Fee",
            "You have received a confirmation by e-mail": "You have received a confirmation by e-mail",
            "Receipt": "Receipt",
            "Download Receipt": "Download Receipt",
            "Tickets": "Tickets",
            "Download Tickets": "Download Tickets",
            "Download Passbook Ticket": "Download Passbook Ticket",
            "Delivery Method": "Delivery Method",
            "Payment Method": "Payment Method",
            "Order Total Price": "Order Total Price"
        }
    },
    de: {
        translation: {
            "Next": "Weiter",
            "Submit": "Submit",
            "Submit Promotion": "Submit",
            "Go Back": "Go back",
            "Select an option": "Bitte wählen Sie eine Option",
            "Promotion Code": "Promotion Code",
            "Delivery and Payment Fee": "Kosten für Lieferung und Zahlung",
            "You have received a confirmation by e-mail": "You have received a confirmation by e-mail",
            "Receipt": "Kaufbeleg",
            "Download Receipt": "Download Kaufbeleg",
            "Tickets": "Tickets",
            "Download Tickets": "Download Tickets",
            "Download Passbook Ticket": "Download Passbook Ticket",
            "Delivery Method": "Lieferart",
            "Payment Method": "Zahlungsart",
            "Order Total Price": "Total inkl. MWST"
        }
    },
    it: {
        translation: {
            "Next": "Next",
            "Submit": "Submit",
            "Submit Promotion": "Submit",
            "Go Back": "Go back",
            "Select an option": "Selezionate un'opzione",
            "Promotion Code": "Promotion Code",
            "Delivery and Payment Fee": "Delivery and Payment Fee",
            "You have received a confirmation by e-mail": "You have received a confirmation by e-mail",
            "Receipt": "Receipt",
            "Download Receipt": "Download Receipt",
            "Tickets": "Tickets",
            "Download Tickets": "Download Tickets",
            "Download Passbook Ticket": "Download Passbook Ticket",
            "Delivery Method": "Delivery Method",
            "Payment Method": "Payment Method",
            "Order Total Price": "Order Total Price"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "de", 
        interpolation: {
            escapeValue: false 
        }
    });

export default i18n;