import React from 'react';
import { useTranslation } from 'react-i18next';

function PromotionCodeTextBox({ promotionCode, onChange, onSubmit }) {

    const { t } = useTranslation();

    const onChangeText = async (e) => {
        onChange(e.target.value);
    }

    const onClick = async (e) => {
        onSubmit();
    }

    return (
        <div className="form-group row g-2">
            <div className="col-auto">
                <input type="text" className="form-control" placeholder={t('Promotion Code')} aria-label={t('Promotion Code')} value={promotionCode ?? ""} onChange={onChangeText} />
            </div>
            <div className="col-auto">
                <button className="btn btn-secondary me-3" type="button" onClick={onClick}>{t('Submit Promotion')}</button>
            </div>
        </div>
    );
}

export default PromotionCodeTextBox;