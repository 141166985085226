import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addTicketsToOrder, api, changeDeliveryMethod, changePaymentType, confirmOrder, createOptionsOfOrder, createShopBasketOrder, FieldControl, getAddressOfOrder, getEventById, getEventInfoByLanguage, getEventInfosByEventId, getFormToolControlsByEventId, getMappedAddress, getMappedOrderOptions, getOptionsOfOrder, getShopBasketOrder, removeTicketsFromOrder, updateBuyerAddress, updateOptionsOfOrder } from "ticketino-api-client";
import FormToolControl from './FormToolControl';
import PaymentPage from './PaymentPage';
import SummaryPage from './SummaryPage';
import ConfirmationPage from './ConfirmationPage';

function Home() {

    const orderKey = "ticketino_form_order_id";
    const baseProxyUrl = process.env.REACT_APP_PROXY;
    const datatransUrl = process.env.REACT_APP_DATATRANS_TICKETINO;
    const htmlElement = document.getElementById("ticketinoAdvancedFormPlugin");

    const handledByDatatrans = [1, 7, 8, 9, 10, 11, 14, 16];

    const { t, i18n } = useTranslation();

    const [event, setEvent] = useState({});
    const [order, setOrder] = useState({});
    const [formToolControls, setFormToolControls] = useState([]);
    const [ticketsToAdd, setTicketsToAdd] = useState([]);
    const [optionsToAdd, setOptionsToAdd] = useState([]);

    const [pages, setPages] = useState(new Set());
    const [pageIndex, setPageIndex] = useState(0);

    const [loading, setLoading] = useState(true);
    const [formBlocked, setFormBlocked] = useState(false);
    const [displaySummary, setDisplaySummary] = useState(false);
    const [displayPaymentPage, setDisplayPaymentPage] = useState(false);
    const [displayConfirmationPage, setDisplayConfirmationPage] = useState(false);
    const [languageId, setLanguageId] = useState(true);

    //predefine the controls that we need to display
    const filterFormToolControls = (formToolControls, pages, pageIndex) => {

        if (pages.length < pageIndex) {
            return [];
        }

        //filter out the controls that are not in the current page
        const formToolControlsFilteredByPage = formToolControls.filter(ftc => ftc.pageNumber === pages[pageIndex]);

        //filter out the controls that are hidden by a ticket type 
        const ticketTypeIds = ticketsToAdd.length > 0 ? new Set(ticketsToAdd.map(t => t.ticketTypeId)) : new Set();
        const formToolControlsFilteredByTicketTypeId = formToolControlsFilteredByPage.filter(ftc => ftc.displayByTicketTypeId == null || ticketTypeIds.has(ftc.displayByTicketTypeId));

        //todo filter out the controls that are hidden by an option

        //todo filter out the controls that are hidden by an option value

        return formToolControlsFilteredByTicketTypeId;
    }

    const visibleFormToolControls = useMemo(
        () => filterFormToolControls(formToolControls, pages, pageIndex),
        [formToolControls, pages, pageIndex]
    );

    useEffect(() => {

        addScripts();

        var languageISO = htmlElement.getAttribute("languageISO");

        var languageId = 1;

        switch (languageISO) {
            case 'en':
                languageId = 3;
                break;
            case 'fr':
                languageId = 2;
                break;
            case 'it':
                languageId = 4;
                break;
            default:
                languageId = 1;
        }

        setLanguageId(languageId);

        axios.get(`${baseProxyUrl}/token`).then((result) => {

            api.defaults.headers.common['Authorization'] = "Bearer " + result.data;

            var eventId = htmlElement.getAttribute("eventId");
            loadForm(eventId);
        });

    }, [])

    const addScripts = () => {
        const datatransScript = document.createElement('script');
        datatransScript.src = "https://pay.datatrans.com/upp/payment/js/datatrans-1.0.2.js";
        document.head.appendChild(datatransScript);

        var jqueryScript = document.createElement('script');
        jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js";
        document.head.appendChild(jqueryScript);
    }

    //start the datatrans process
    const startDatatrans = async (orderId) => {

        axios
            .get(`${datatransUrl}/Datatrans/${orderId}/DigitalSignature`)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form className='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                element.setAttribute("data-success-url", `${baseProxyUrl}/confirm/success/${orderId}`);
                element.setAttribute("data-error-url", `${baseProxyUrl}/confirm/error/${orderId}`);
                element.setAttribute("data-cancel-url", `${baseProxyUrl}/confirm/cancel/${orderId}`);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            });
    }

    const onlyAllowOneOptionSelected = (formToolControl) => {

        const fieldControlsWithMoreThanOneOptionSelectable = [
            FieldControl.CheckboxList
        ];

        return fieldControlsWithMoreThanOneOptionSelectable.find(fc => fc === formToolControl.fieldControlId) == null;
    }

    const loadForm = async (eventId) => {
        try {

            //event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);
            const info = getEventInfoByLanguage(infos.eventInfos, languageId);

            const updatedEventInfo = { ...event, info: info };
            setEvent(updatedEventInfo);

            let formToolControls = await getFormToolControlsByEventId(eventId);
            formToolControls = formToolControls.map(ftc => {
                return {
                    ...ftc,
                    onlyAllowOneOptionSelected: onlyAllowOneOptionSelected(ftc),
                    values: []
                };
            }).sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

            setFormToolControls(formToolControls);

            const pages = [...new Set(formToolControls.map(ftc => ftc.pageNumber))];
            setPages(pages);

            await getOrder();

            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const createOrder = async () => {
        const order = await createShopBasketOrder({ currency: "CHF", tenantId: 1, pointOfSaleId: 6585 });
        return order;
    }

    const getOrder = async () => {

        const orderId = sessionStorage.getItem(orderKey);

        let order = null;

        if (orderId) {
            try {
                order = await getShopBasketOrder(parseInt(orderId));
            }
            // if it crashes create a new order, most likely the order doesn't exist in the server anymore
            catch (error) {
                order = await createOrder();
            }
        }
        else {
            order = await createOrder();
        }

        sessionStorage.setItem(orderKey, order.id);
        setOrder(order);

        return order;
    }

    const setPaymentType = async (paymentTypeId) => {

        if (order?.id == null) return;

        setFormBlocked(true);

        try {
            const updatedOrder = await changePaymentType(order.id, paymentTypeId);
            setOrder(updatedOrder);
        }
        catch (e) {
            throw e;
        }
        finally {
            setFormBlocked(false);
        }
    }

    const setDeliveryMethod = async (deliveryMethodId) => {

        if (order?.id == null) return;

        setFormBlocked(true);

        try {
            const updatedOrder = await changeDeliveryMethod(order.id, deliveryMethodId);
            setOrder(updatedOrder);
        }
        catch (e) {
            throw e;
        }
        finally {
            setFormBlocked(false);
        }
    }

    const addFormToolControlValue = (formToolControlId, value) => {

        const updatedFormToolControls = formToolControls.map(ftc => {
            if (ftc.id === formToolControlId) {

                if (ftc.onlyAllowOneOptionSelected) {
                    ftc.values = [value];
                }
                else {
                    ftc.values.push(value);
                }

                return ftc;
            }
            else {
                return ftc;
            }
        });

        setFormToolControls(updatedFormToolControls);
    }

    const removeFormToolControlValue = (formToolControlId, value) => {

        const updatedFormToolControls = formToolControls.map(ftc => {
            if (ftc.id === formToolControlId) {
                ftc.values = ftc.values.filter(v => v !== value);
                return ftc;
            }
            else {
                return ftc;
            }
        });

        setFormToolControls(updatedFormToolControls);
    }

    const addTicket = async (ticket) => {

        setFormBlocked(true);

        try {
            const tickets = ticketsToAdd.filter(t => t.id !== ticket.id);

            const addTicketsToOrderApiModel = {
                ticketsToAdd: [
                    {
                        ticketTypeId: ticket.ticketTypeId,
                        quantity: 1,
                        promotionCode: ticket.promotionCode
                    }
                ]
            }

            const updatedOrder = await addTicketsToOrder(order.id, addTicketsToOrderApiModel);
            console.log(updatedOrder);
            setOrder(updatedOrder);


            //get the last ticket with the given ticket type
            //we can assume that it's the one that was added here
            var lastTicketCreated = updatedOrder.tickets
                .sort((a, b) => (a.bookedDate < b.bookedDate) ? 1 : -1)
                .find(t => t.ticketTypeId === ticket.ticketTypeId);

            ticket = { ...ticket, id: lastTicketCreated.id };

            tickets.push(ticket);
            setTicketsToAdd(tickets);

            addFormToolControlValue(ticket.formToolControlId, ticket.value);
        }
        catch (e){
            throw e;
        }
        finally {
            setFormBlocked(false);
        }

        return ticket;
    };

    const removeTicket = async (id) => {

        setFormBlocked(true);

        try {
            const ticket = ticketsToAdd.find(t => t.id === id);

            if (ticket?.formToolControlId == null) {
                return;
            }

            removeFormToolControlValue(ticket.formToolControlId, ticket.value);

            const filteredTicketsToAdd = ticketsToAdd.filter(t => t.id !== id);

            setTicketsToAdd(filteredTicketsToAdd);

            const updatedOrder = await removeTicketsFromOrder(order.id, [id]);
            console.log(updatedOrder);
            setOrder(updatedOrder);
        }
        catch (e) {
            throw e;
        }
        finally {
            setFormBlocked(false);
        }
    };

    const addOption = (option) => {

        let options = optionsToAdd.filter(o => o.field !== option.field);
        options.push(option);
        setOptionsToAdd(options);
        addFormToolControlValue(option.formToolControlId, option.value);

        return option;
    };

    const removeOption = (option) => {

        const filteredOptionsToAdd = optionsToAdd.filter(o => o.field !== option.field);

        removeFormToolControlValue(option.formToolControlId, option.value);
        setTicketsToAdd(filteredOptionsToAdd);
    };

    const setPage = (pageIndex) => {
        setPageIndex(pageIndex);

        //after the summary we must display the payment page
        if (pages.length === pageIndex - 1) {
            setDisplayPaymentPage(true);
        }
        else {
            setDisplayPaymentPage(false);
        }

        //if the page selected is the next to the last page defined in the form we must display the summary
        if (pages.length === pageIndex) {
            setDisplaySummary(true);
        }
        else {
            setDisplaySummary(false);
        }
    }

    const onSubmit = async (e) => {

        //prevent the submit behavior
        e.preventDefault();

        //check if there are more pages in the form
        if (pageIndex <= pages.length) {
            setPage(pageIndex + 1);
        }
        //last page -> submit order
        else {
            
            //add order options

            console.log(optionsToAdd);

            const mappings = optionsToAdd.map(o => { return { id: o.field, value: o.optionText }; });
            console.log(mappings);

            let orderOptions = null;

            try {
                orderOptions = await getOptionsOfOrder(order.id);
            }
            catch (e) {

            }

            console.log(orderOptions);

            const mappedOrderOptions = getMappedOrderOptions(orderOptions?.id ?? 0, order.id, mappings);

            orderOptions = { ...orderOptions, ...mappedOrderOptions };

            //in case that the options have been submited we must updated them, not create them
            const result = orderOptions?.id == null || orderOptions?.id === 0 ?
                await createOptionsOfOrder(order.id, orderOptions) :
                await updateOptionsOfOrder(order.id, orderOptions);

            console.log(result);

            try {
                //add address fields

                let addressOfOrder = await getAddressOfOrder(order.id);
                console.log(addressOfOrder);

                const addressOptions = getMappedAddress(mappings);
                console.log(addressOptions);

                addressOfOrder = { ...addressOfOrder, ...addressOptions };
                //todo remove after set
                addressOfOrder.countryId = 176;

                const updatedAddressOfOrder = await updateBuyerAddress(order.id, addressOfOrder);
                console.log(updatedAddressOfOrder);
            }
            catch (e) {

            }

            if (handledByDatatrans.includes(order.paymentMethodId)) {
                await startDatatrans(order.id);
            }
            else {
                //complete otder
                var updatedOrder = await confirmOrder(order.id);
                setOrder(updatedOrder);
                sessionStorage.clear();

                setDisplayPaymentPage(false);
                setDisplayConfirmationPage(true);
            }
        }
    };

    const onGoBack = (e) => {

        if (pageIndex <= 0) {
            return;
        }

        setPage(pageIndex - 1);
    };

    return (
        <>
            <div id="datatrans-form-placeholder"></div>
            {!loading && <form onSubmit={onSubmit} autoComplete="off" inert={formBlocked ? '' : undefined}>
                {visibleFormToolControls.map((ftc, index) => <FormToolControl formToolControl={ftc} language={languageId} order={order} addTicket={addTicket} removeTicket={removeTicket} addOption={addOption} removeOption={removeOption} />)}
                {displaySummary && <SummaryPage formToolControls={formToolControls} language={languageId} />}
                {displayPaymentPage && <PaymentPage event={event} order={order} language={languageId} setPaymentType={setPaymentType} setDeliveryMethod={setDeliveryMethod} />}
                {displayConfirmationPage && <ConfirmationPage order={order} organizerId={event.organizerId} />}
                {/* don't display the buttons in the confirmation page because it's the last page and we shouldn't be able to go forward or backwards */}
                {!displayConfirmationPage && <div className="row mb-3 pt-4">
                    {pageIndex > 0 && <div className="col-md-2"><button className="btn btn-secondary me-3" type="button" onClick={onGoBack}>{t('Go Back')}</button></div>}
                    <div className="col-md-1"><input className="btn btn-primary" type="submit" value={t('Next')} /></div>
                </div>}
            </form >}
        </>
    );
}

export default Home;