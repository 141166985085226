import React from 'react';
import { DataSaveType } from "ticketino-api-client";
import StaticField from './DataSaveTypes/StaticField';
import CreateATicket from './DataSaveTypes/CreateATicket';
import SaveToField from './DataSaveTypes/SaveToField';

function FormToolControl({ formToolControl, language, order, addTicket, removeTicket, addOption, removeOption }) {
    return (
        <div key={formToolControl.id} className="row mb-3">
            {formToolControl.dataSaveTypeId === DataSaveType.StaticField && <StaticField formToolControl={formToolControl} language={language} />}
            {formToolControl.dataSaveTypeId === DataSaveType.CreateATicket && <CreateATicket formToolControl={formToolControl} language={language} order={order} addTicket={addTicket} removeTicket={removeTicket} />}
            {formToolControl.dataSaveTypeId === DataSaveType.SaveToField && <SaveToField formToolControl={formToolControl} language={language} addOption={addOption} removeOption={removeOption} />}
        </div>
    );
}

export default FormToolControl;