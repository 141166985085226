import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPaymentMethods, getDeliveryMethods, getAdmittedPaymentTypesOfOrder, getAdmittedDeliveryMethodsOfOrder, getLocationInfoByLanguage, getLocationInfos, getTicketTypeInfoByLanguage, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";

function PaymentPage({ event, order, language, setPaymentType, setDeliveryMethod }) {

    const { t } = useTranslation();

    const [locationInfo, setLocationInfo] = useState({});
    const [tickets, setTickets] = useState([]);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
    const [availableDeliveryMethods, setAvailableDeliveryMethods] = useState([]);

    useEffect(() => {
        loadPaymentPage();
    }, [])

    const loadPaymentPage = async () => {

        if (event.locationId > 0) {
            const locationInfos = await getLocationInfos(event.locationId);
            const locationInfo = getLocationInfoByLanguage(locationInfos, language);
            setLocationInfo(locationInfo);
        }

        const tickets = await Promise.all(
            order.tickets.map(async (t) => {
                const ticketTypeInfos = await getTicketTypeInfosByTicketTypeId(t.ticketTypeId);
                const ticketTypeInfo = getTicketTypeInfoByLanguage(ticketTypeInfos, language);

                return { name: ticketTypeInfo?.name ?? "", price: t.price, currency: t.currency };
            }));

        setTickets(tickets);

        const htmlElement = document.getElementById("ticketinoAdvancedFormPlugin");
        var languageISO = htmlElement.getAttribute("languageISO");

        const paymentMethods = await getPaymentMethods(languageISO);
        const deliveryMethods = await getDeliveryMethods(languageISO);

        console.log(paymentMethods);
        console.log(deliveryMethods);

        const availablePaymentMethodIds = await getAdmittedPaymentTypesOfOrder(order.id);

        const availablePaymentMethods = availablePaymentMethodIds.map(id => {
            const paymentMethod = paymentMethods.find(p => p.id === id);

            if (paymentMethod != null) {
                return paymentMethod;
            } 
        });

        setAvailablePaymentMethods(availablePaymentMethods);

        const availableDeliveryMethodIds = await getAdmittedDeliveryMethodsOfOrder(order.id);

        const availableDeliveryMethods = availableDeliveryMethodIds.map(id => {
            const deliveryMethod = deliveryMethods.find(p => p.id === id);

            if (deliveryMethod != null) {
                return deliveryMethod;
            }
        });

        setAvailableDeliveryMethods(availableDeliveryMethods);
    }

    return (
        <>
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    <h4>{t("Delivery Method")}</h4>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    {availableDeliveryMethods.length > 0 && availableDeliveryMethods.map(dm =>
                        <div className="form-check mb-4">
                            <input type="radio" className="form-check-input" id={dm.id} name="DeliveryMethod" value={dm.id} onChange={() => { setDeliveryMethod(dm.id) }} required={true} />{dm.description}
                            <br /><i>{dm.detail}</i>
                        </div>
                    )}
                </div>
            </div>
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    <h4>{t("Payment Method")}</h4>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    {availablePaymentMethods.length > 0 && availablePaymentMethods.map(pm => 
                        <div className="form-check mb-4">
                            <input type="radio" className="form-check-input" id={pm.id} name="PaymentMethod" value={pm.id} onChange={() => { setPaymentType(pm.id); }} required={true} />{pm.description}
                            <br /><i>{pm.detail}</i>
                        </div>
                    )}
                </div>
            </div> 
            <div className="row mb-3 pt-4">
                <div className="col-md-6">
                    <h4>Zusammenfassung</h4>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-8">
                    {event?.info?.name}
                    <br />{event?.start}
                    {locationInfo?.name != null && <><br />{locationInfo.name}</>}
                </div>
            </div>
            {tickets.length > 0 && tickets.map((t, index) =>
                <div className={`row mb-2 ${index % 2 === 0 ? "" : "bg-light"}`}>
                    <div className="col-md-8">{t.name}</div>
                    <div className="col-md-4 float-end">
                        <div className="float-end">{t.currency} {t.price}</div>
                    </div>
                </div>
            )}
            {(order.deliveryMethodId != null || order.paymentMethodId != null) && 
                <div className={`row mb-2 ${tickets.length % 2 === 0 ? "" : "bg-light"}`}>
                    <div className="col-md-8">{t("Delivery and Payment Fee")}</div>
                    <div className="col-md-4 float-end">
                        <div className="float-end">{order.currency} {order.deliverPaymentFee ?? 0 + order.deliverPaymentFeeVat ?? 0}</div>
                    </div>
                </div>
            }
            <div className={`row mb-2 ${tickets.length % 2 !== 0 ? "" : "bg-light"}`}>
                <div className="col-md-8">
                    <b>{t("Order Total Price")}</b>
                </div>
                <div className="col-md-4">
                    <div class="float-end"><b>{order.currency} {order.totalPrice ?? 0}</b></div>
                </div>
            </div>
        </>
    );
}

export default PaymentPage;