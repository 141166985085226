import React, { useEffect, useState } from 'react';
import {
    FieldControl,
    TicketBookableStatus,
    getFormToolControlInfosByFormToolControlId,
    getFormToolControlInfoByLanguage,
    getFormToolControlOptionInfoByLanguage,
    getFormToolControlOptionsByFormToolControlId,
    getFormToolControlOptionInfosByFormToolControlId,
    getTicketTypeNumberOfAvailableTickets
} from "ticketino-api-client";
import Checkbox from './../FieldControls/Checkbox';
import RadioButtonList from './../FieldControls/RadioButtonList';
import Dropdown from './../FieldControls/Dropdown';
import CheckboxList from './../FieldControls/CheckboxList';
import PromotionCodeTextBox from './../Utils/PromotionCodeTextBox';

function CreateATicket({ formToolControl, language, order, addTicket, removeTicket }) {

    const ticketsAddedKey = `tickets_added_${formToolControl.id}`;

    const [formToolControlInfo, setFormToolControlInfo] = useState({});
    const [formToolControlOptions, setFormToolControlOptions] = useState([]);
    const [selectedFormToolControlOption, setSelectedFormToolControlOption] = useState({});
    //used to keep track of the ticket types added in this component
    const [ticketsAdded, setTicketsAdded] = useState([]);
    //when true we must display the box to enter the promotion code
    const [ticketTypeUnlockedByPromotionCode, setTicketTypeUnlockedByPromotionCode] = useState(false);
    const [promotionCode, setPromotionCode] = useState("");

    useEffect(() => {
        loadFormToolControl(formToolControl.id);
    }, [])

    const loadFormToolControl = async (formToolControlId) => {
        try {

            const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
            const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);

            setFormToolControlInfo(formToolControlInfo);

            const tickets = JSON.parse(sessionStorage.getItem(ticketsAddedKey));

            if (tickets?.length > 0) {
                setTicketsAdded(tickets);
            }

            const formToolControlOptions = await getFormToolControlOptionsByFormToolControlId(formToolControlId);

            let updatedFormToolControlOptions = await Promise.all(
                formToolControlOptions.map(async (formToolControlOption) => {

                    const formToolControlOptionInfos = await getFormToolControlOptionInfosByFormToolControlId(formToolControlOption.id);
                    const formToolControlOptionInfo = getFormToolControlOptionInfoByLanguage(formToolControlOptionInfos, language);

                    //check availability
                    let ticketTypeAvailability = null;

                    if (formToolControlOption?.ticketTypeId) {
                        ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(formToolControlOption.ticketTypeId, order.id);

                        var unlockedByPromotionCode = ticketTypeAvailability.status === TicketBookableStatus.CurrentlyUnavailable && ticketTypeAvailability.availableTickets === 0;
                        ticketTypeAvailability.unlockedByPromotionCode = unlockedByPromotionCode;

                        var availableForSale = ticketTypeAvailability.status === TicketBookableStatus.Available && ticketTypeAvailability.availableTickets > 0;
                        //in case that it's unlocked by a promotion code it must be available to book it too
                        ticketTypeAvailability.available = availableForSale || unlockedByPromotionCode;
                    }

                    return { ...formToolControlOption, info: formToolControlOptionInfo, availability: ticketTypeAvailability }
                })
            );

            updatedFormToolControlOptions = updatedFormToolControlOptions.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

            setFormToolControlOptions(updatedFormToolControlOptions);
        }
        catch (error) {
            console.error(error);
        }
    }

    const modifySelectedTicket = async (formToolControlOption) => {

        //get the ticket that was previously added
        const ticketAdded = formToolControl.onlyAllowOneOptionSelected ?
            ticketsAdded[0] :
            ticketsAdded.find(t => t.ticketTypeId === formToolControlOption?.ticketTypeId);

        let updatedTicketsAdded = ticketsAdded;

        //remove the ticket that was previouly added
        //in case that only an option can be selected
        //or that a ticket with the same ticket type was added
        if (ticketAdded != null) {
            await removeTicket(ticketAdded.id);
            updatedTicketsAdded = updatedTicketsAdded.filter(t => t.id !== ticketAdded.id);
        }

        //add the new one
        const addTicketResult = await addTicket({
            ticketTypeId: formToolControlOption.ticketTypeId,
            value: formToolControlOption.id,
            formToolControlId: formToolControl.id,
            promotionCode: formToolControlOption.availability?.unlockedByPromotionCode === true ? promotionCode : ''
        });

        updatedTicketsAdded.push(addTicketResult);
        setTickets(updatedTicketsAdded);
    }

    const setTickets = (tickets) => {
        sessionStorage.setItem(ticketsAddedKey, JSON.stringify(tickets));
        setTicketsAdded(tickets);
    }

    const onChange = async (e) => {

        const formToolControlOptionId = e.value;

        const formToolControlOption = formToolControlOptions.find(ftco => ftco.id === parseInt(formToolControlOptionId));
        setSelectedFormToolControlOption(formToolControlOption);

        //when the FieldControl only allows one option selected we must delete the rest
        if (formToolControl.onlyAllowOneOptionSelected && formToolControlOption == null) {

            await ticketsAdded.forEach(async t => {
                await removeTicket(t.id);
            });

            setTickets([]);
        }

        //the option must have a ticket type assigned to it
        if (formToolControlOption?.ticketTypeId == null) {
            return;
        }

        //when the ticket type needs a promotion code, display the text box
        const unlockedByPromotionCode = formToolControlOption.availability?.unlockedByPromotionCode ?? false;
        setTicketTypeUnlockedByPromotionCode(unlockedByPromotionCode);
        setPromotionCode("");

        if (unlockedByPromotionCode) {

            //remove the other tickets in case that only one is allowed
            if (formToolControl.onlyAllowOneOptionSelected) {

                await ticketsAdded.forEach(async t => {
                    await removeTicket(t.id);
                });

                setTickets([]);
            }

            return;
        }

        await modifySelectedTicket(formToolControlOption);
    }

    const onSubmitPromotionCode = async () => {

        if (selectedFormToolControlOption == null || promotionCode == null) {
            return;
        }

        const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(selectedFormToolControlOption.ticketTypeId, order.id, promotionCode);

        //check for the valid status and that there are still available tickets
        if (ticketTypeAvailability?.status === 0 && ticketTypeAvailability?.availableTickets > 0) {
            await modifySelectedTicket(selectedFormToolControlOption);
        }
    }


    const onChangePromotionCode = async (e) => {
        setPromotionCode(e);
    }

    return (
        <>
            {formToolControl.fieldControlId === FieldControl.Checkbox && <Checkbox formToolControl={formToolControl} language={language} addTicket={addTicket} removeTicket={removeTicket} />}
            {formToolControl.fieldControlId === FieldControl.CheckboxList && <CheckboxList formToolControl={formToolControl} language={language} addTicket={addTicket} removeTicket={removeTicket} />}
            {formToolControl.fieldControlId === FieldControl.RadioButtonList && <RadioButtonList formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} formToolControlOptions={formToolControlOptions} onChange={onChange} />}
            {formToolControl.fieldControlId === FieldControl.Dropdown && <Dropdown formToolControl={formToolControl} formToolControlInfo={formToolControlInfo} formToolControlOptions={formToolControlOptions} onChange={onChange} />}
            {ticketTypeUnlockedByPromotionCode && <PromotionCodeTextBox promotionCode={promotionCode} onSubmit={onSubmitPromotionCode} onChange={onChangePromotionCode} />}
        </>
    );
}

export default CreateATicket;