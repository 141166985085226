import React, { useEffect, useState } from 'react';
import { getFormToolControlInfosByFormToolControlId, getFormToolControlInfoByLanguage, FieldControl } from "ticketino-api-client";
import TextLabel from './../FieldControls/TextLabel';
import Header1 from './../FieldControls/Header1';
import Header2 from './../FieldControls/Header2';

function StaticField({ formToolControl, language }) {

    const [formToolControlInfo, setFormToolControlInfo] = useState({});

    useEffect(() => {
        loadFormToolControl(formToolControl.id)
    }, [])

    const loadFormToolControl = async (formToolControlId) => {
        try {
            const formToolControlInfos = await getFormToolControlInfosByFormToolControlId(formToolControlId);
            const formToolControlInfo = getFormToolControlInfoByLanguage(formToolControlInfos, language);
            setFormToolControlInfo(formToolControlInfo);
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {formToolControl.fieldControlId === FieldControl.Header1 && <Header1 text={formToolControlInfo?.titleText ?? ""} />}
            {formToolControl.fieldControlId === FieldControl.Header2 && <Header2 text={formToolControlInfo?.titleText ?? ""} />}
            {formToolControl.fieldControlId === FieldControl.TextLabel && <TextLabel text={formToolControlInfo?.titleText ?? ""} />}
        </>
    );
}

export default StaticField;