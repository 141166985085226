import React from 'react';

function CheckboxList({ formToolControl, formToolControlInfo, formToolControlOptions, onChange }) {

    const onChangeCheckbox = async (e) => {
        onChange({ value: e.target.id });
    }

    return (
        <div className="form-group">
            {formToolControlInfo?.titleText && <label className="form-label">{formToolControlInfo.titleText}</label>}
            {formToolControlOptions?.length > 0 && formToolControlOptions.map((ftco, index) => 
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id={ftco.id} checked={formToolControl.values.includes(ftco.id)} onChange={onChangeCheckbox} required={formToolControl.isMandatory} disabled={ftco.availability?.available === false} />
                    <label className="form-check-label" htmlFor={ftco.id}>{ftco.info?.optionText}</label>
                </div>
            )}
        </div>
    );
}

export default CheckboxList;