import React from 'react';
import { useTranslation } from 'react-i18next';

function Dropdown({ formToolControl, formToolControlInfo, formToolControlOptions, onChange }) {

    const { t } = useTranslation();

    const onChangeDropdown = async (e) => {
        onChange({ value: e.target.value });
    }

    return (
        <div className="form-group">
            {formToolControlInfo?.titleText && <label className="form-label">{formToolControlInfo.titleText}</label>}
            <select className="form-select" id={formToolControl.id} value={formToolControl.values[0]} required={formToolControl.isMandatory} onChange={onChangeDropdown}>
                <option key={0} value="">{t('Select an option')}</option>
                {formToolControlOptions.map((ftco, index) =>
                    <option key={ftco.id} value={ftco.id} disabled={ftco.availability?.available === false}>{ftco.info?.optionText}</option>
                )}
            </select>
        </div>
    );
}

export default Dropdown;