import React from 'react';

function Textarea({ formToolControl, formToolControlInfo, onChange }) {

    const onChangeText = async (e) => {
        onChange({ value: e.target.value });
    }

    return (
        <div className="form-group">
            {formToolControlInfo?.titleText && <label className="form-label">{formToolControlInfo.titleText}</label>}
            <textarea type="text" className="form-control" id={formToolControl.id} onChange={onChangeText} value={formToolControl.values[0] ?? ""} />
        </div>
    );
}

export default Textarea;