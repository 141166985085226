import React from 'react';

function RadioButtonList({ formToolControl, formToolControlInfo, formToolControlOptions, onChange }) {

    const onChangeRadioButtonList = async (e) => {
        onChange({ value: e.target.id });
    }

    return (
        <div className="form-group">
            {formToolControlInfo?.titleText && <label className="form-label">{formToolControlInfo.titleText}</label>}
            {formToolControlOptions?.length > 0 && formToolControlOptions.map((ftco, index) => 
                <div key={ftco.id} className="form-check">
                    <input className="form-check-input" type="radio" id={ftco.id} name={formToolControl.id} checked={formToolControl.values.includes(ftco.id)} value={ftco.info?.optionText} required={formToolControl.isMandatory} onChange={onChangeRadioButtonList} disabled={ftco.availability?.available === false} />
                    <label className="form-check-label" htmlFor={ftco.id}>{ftco.info?.optionText}</label>
                </div>
            )}
        </div>
    );
}

export default RadioButtonList;