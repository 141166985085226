import React from 'react';

function OptionTextBox({ optionText, onChange }) {

    const onChangeText = async (e) => {
        onChange(e.target.value);
    }

    return (
        <div className="form-group">
            <input type="text" className="form-control" value={optionText ?? ""} onChange={onChangeText} required={true} />
        </div>
    );
}

export default OptionTextBox;